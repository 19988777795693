<template>
    <div class="mx-5 sm:mx-16 md:mx-20 text-white sm:text-lg justify-items-center grid grid-cols-1 text-center gap-5 sm:gap-7">
        <img 
            src="../assets/images/furnitures/text-image.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-80 sm:w-96"
        />
        <p>Crafting the future of furniture design with customized hand made pieces.</p>
        <p>We source the best materials and shape them into any design that reflects innovation and style.</p>
        <img 
            src="../assets/images/furnitures/11.jpg"
            class="rounded-lg shadow-xl h-auto lg:w-2/3"
        />
    </div>
</template>